import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Les émotions peuvent avoir un impact important sur notre poids. En effet, elles peuvent nous amener à manger plus ou moins, et à faire des choix alimentaires plus ou moins sains.`}</p>
    <p>{`Certaines émotions peuvent donc nous faire prendre du poids, et si on ne les gère pas correctement, elles peuvent devenir des kilos émotionnels.`}</p>
    <p>{`Il est donc important de savoir les gérer, pour ne pas laisser les émotions prendre le dessus sur notre corps et notre santé.`}</p>
    <p>{`Voici quelques conseils pour perdre les kilos émotionnels :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Identifiez vos émotions`}</strong>{` : cela vous permettra de mieux les comprendre et de mieux les gérer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Prenez soin de vous`}</strong>{` : faites de l'exercice, mangez équilibré, dormez suffisamment, etc. Prenez soin de votre corps et de votre santé, cela vous aidera à mieux gérer vos émotions.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Parlez-en à un proche ou à un professionnel`}</strong>{` : parler de ce que l'on ressent peut aider à mieux comprendre et à gérer nos émotions. Cela peut être un bon moyen de ne pas laisser les émotions prendre le dessus.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      